import React from 'react';
import Accordion from '../components/accordion';
import logo from './../assets/img/logo.svg';

class Home extends React.Component {

    state= {
        closeAll: false
    }

    renderAccordion = (data) =>{
        return <Accordion closeAll={this.state.closeAll} data={data}/>;
    }

    closeAllTabs = () => {
        this.setState({closeAll:true}, ()=>{
            setTimeout(()=>{
                this.setState({closeAll:false})
            }, 300)
        })
    }
  
    render (){
        const data= [
            {
                title: "About",
                paragraph: <div className='accordion-data'><h1>Seenko Investments is a private investment company based in Beirut, Lebanon.</h1></div>
            },
            {
                title: "Business",
                paragraph:  <div className='accordion-data'>
                                <h1>We specialise in multi asset classes including: equity, debt, real estate and venture capital.</h1>
                                <p>Investment proposals can be submitted by email to <a href="mailto:info@seenko.com" rel="noreferrer" target="_blank"> info@seenko.com</a></p>
                            </div>
            },
            {
                title: "Careers",
                paragraph: <div className='accordion-data'><h1>CV and accompanying cover letters can be submitted by email to <a href="mailto:info@seenko.com" rel="noreferrer" target="_blank">info@seenko.com</a></h1></div>
            },
            {
                title: "Contact",
                paragraph: <div className='accordion-data'>
                <h1>3rd Floor<br/>
                    Zeena Tower<br/>
                    Corniche El Nahr<br/>
                    Beirut, Lebanon<br/><br/>
                    <span><a href="mailto:info@seenko.com" rel="noreferrer" target="_blank">info@seenko.com</a><br/>
                            <a href="https://www.linkedin.com/company/ceenko-investments/" rel="noreferrer" target="_blank">LinkedIn</a>
                    </span>
                </h1></div>
            },
        ];
        return(
            <div className='home-page'>
                <div className='right-div'>
                    <div className='logo' onClick={this.closeAllTabs}>
                        <img src={logo} alt="logo"/>
                    </div>
                    <div className='right-div-contact'>
                        <h3>Contact</h3>
                        <p>3rd Floor<br/>
                            Zeena Tower<br/>
                            Corniche El Nahr<br/>
                            Beirut, Lebanon
                        </p>
                        <p><a href="mailto:info@seenko.com" rel="noreferrer" target="_blank">info@seenko.com</a><br/>
                            <a href="https://www.linkedin.com/company/ceenko-investments/" rel="noreferrer" target="_blank">LinkedIn</a>
                        </p>
                    </div>
                </div>
                <div className='left-div'>{this.renderAccordion(data)}</div>
            </div>
        );
    }
}

export default Home;

