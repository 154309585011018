import React from 'react';


class Accordion extends React.Component {
  state = {
    opened: false,
  }

  componentDidUpdate(prevProps){
    if(this.props.closeAll !== prevProps.closeAll && this.props.closeAll === true){
        this.setState({opened: false});
    }
  }

  setOpened = (openItem) => {
    if(this.state.opened === openItem){
      this.setState({opened: false});
    }else{
      this.setState({opened: openItem});
    }
  }

    render () {
        return (
            <div className='wrapper'>
                <ul className='accordion-list'>
                    {this.props.data.map((data, key) => {
                        return (
                            <li className={'accordion-list__item ' + data.title.toLowerCase()} key={"AccordionItem-"+ key}>
                                <AccordionItem isOpened={this.state.opened === data.title} open={this.setOpened} {...data}/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

class AccordionItem extends React.Component {

  render () {
    const {
      props: {
        paragraph,
        title,
        link,
        open, // function to toggle
        isOpened // flag
      }
    } = this;
    
    return (
      <div
        {...{
          className: `accordion-item, ${isOpened && 'accordion-item--opened'}`,
          onClick: () => { open(title); }
        }}
      >
        <div {...{ className: 'accordion-item__line ' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
              {(link)? <a {...{ className: 'accordion-item__link' }} to={link.href}> {link.title} </a> : ''}
            </div>
          </div>
      </div>
    )
  }
}

export default Accordion;